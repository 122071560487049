import React from "react";
import { Users, Target, Award, Globe } from "lucide-react";

export default function About() {
  const stats = [
    {
      icon: <Users className="h-8 w-8 text-gradient-to-r from-blue-500 to-violet-500" />,
      number: "500+",
      label: "Clients Satisfaits",
    },
    {
      icon: <Target className="h-8 w-8 text-gradient-to-r from-blue-500 to-violet-500" />,
      number: "98%",
      label: "Taux de Satisfaction",
    },
    {
      icon: <Award className="h-8 w-8 text-gradient-to-r from-blue-500 to-violet-500" />,
      number: "15+",
      label: "Années d'Expérience",
    },
    {
      icon: <Globe className="h-8 w-8 text-gradient-to-r from-blue-500 to-violet-500" />,
      number: "100+",
      label: "Pays Desservis",
    },
  ];

  return (
    <div className="min-h-screen py-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-extrabold text-gray-900 dark:text-white mb-4">
            À Propos de Kingdor
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Votre partenaire de confiance en matière de logistique internationale depuis plus de 15 ans
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20">
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Notre Histoire
            </h2>
            <p className="text-gray-600 dark:text-gray-300">
              Fondée en 2016, Kingdor s'est développée pour devenir un leader dans le secteur de la logistique internationale. Notre engagement envers l'excellence et l'innovation nous a permis de construire un réseau mondial fiable et efficace.
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              Aujourd'hui, nous sommes fiers de servir des clients dans plus de 100 pays, offrant des solutions logistiques complètes adaptées à leurs besoins spécifiques.
            </p>
          </div>
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Notre Mission
            </h2>
            <p className="text-gray-600 dark:text-gray-300">
              Notre mission est de simplifier la complexité du commerce international en fournissant des solutions logistiques innovantes et durables. Nous nous engageons à optimiser les chaînes d'approvisionnement de nos clients tout en respectant les plus hauts standards de qualité et de durabilité.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105"
            >
              <div className="flex justify-center mb-4">{stat.icon}</div>
              <div className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
                {stat.number}
              </div>
              <div className="text-gray-600 dark:text-gray-300">{stat.label}</div>
            </div>
          ))}
        </div>

        <div className="mt-20 bg-white dark:bg-gray-700 rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6 text-center">
            Nos Valeurs
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Excellence
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous visons l'excellence dans chaque aspect de nos services
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Innovation
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous adoptons les dernières technologies pour améliorer nos services
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Intégrité
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous agissons avec honnêteté et transparence dans toutes nos opérations
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
