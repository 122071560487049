import React from 'react';
import { Globe, TrendingUp, Shield } from 'lucide-react';
import About from './About/About';
import Services from './Service/Services';
import ImportExport from './Import-export/ImportExport';
import Contact from './Contact/Contact';
import { Link } from 'react-router-dom';

export default function Home() {
  return (<>
    <div className="relative bg-gradient-to-r from-blue-900 via-indigo-900 to-gray-900 pt-20">
      <div className="absolute inset-0 z-0">
        <img
          className="w-full h-full object-cover opacity-30 mix-blend-overlay"
          src="https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?auto=format&fit=crop&q=80"
          alt="Container ship"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/30 to-black/60"></div>
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-32">
        <div className="text-center">
          <h1 className="text-5xl tracking-tight font-extrabold text-white sm:text-6xl md:text-7xl">
            <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-white">kingdor</span>
            <span className="block text-blue-400 mt-2">Import - Export & Logistique</span>
          </h1>
          <p className="mt-6 max-w-md mx-auto text-lg text-gray-300 sm:text-xl md:mt-8 md:text-2xl md:max-w-3xl leading-relaxed">
            Votre partenaire de confiance en matière de commerce international et de logistique. Nous proposons des solutions homogènes pour vos besoins commerciaux mondiaux
          </p>
          <div className="mt-12 flex flex-col sm:flex-row justify-center gap-6">
            <button className="bg-blue-600 text-white px-10 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transform hover:-translate-y-1 transition-all duration-200 shadow-lg hover:shadow-blue-500/30">
              <Link to="/contact">Contacter</Link>
            </button>
            <button className="bg-white/10 backdrop-blur-lg text-white border border-white/20 px-10 py-4 rounded-lg text-lg font-semibold hover:bg-white/20 transform hover:-translate-y-1 transition-all duration-200">
              <Link to="/about">En savoir plus</Link>
            </button>
          </div>
        </div>

        <div className="mt-32 grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div className="bg-gradient-to-br from-white/20 to-white/5 backdrop-blur-lg p-8 rounded-xl border border-white/10 hover:border-white/30 transition-all duration-300 transform hover:-translate-y-2 group">
            <div className="bg-blue-500/20 p-3 rounded-lg w-fit group-hover:bg-blue-500/30 transition-colors">
              <Globe className="h-8 w-8 text-blue-400" />
            </div>
            <h3 className="mt-6 text-2xl font-bold text-white">Portée mondiale</h3>
            <p className="mt-4 text-gray-300 text-lg leading-relaxed">Présent dans plus de 100 pays à travers le monde</p>
          </div>
          <div className="bg-gradient-to-br from-white/20 to-white/5 backdrop-blur-lg p-8 rounded-xl border border-white/10 hover:border-white/30 transition-all duration-300 transform hover:-translate-y-2 group">
            <div className="bg-blue-500/20 p-3 rounded-lg w-fit group-hover:bg-blue-500/30 transition-colors">
              <TrendingUp className="h-8 w-8 text-blue-400" />
            </div>
            <h3 className="mt-6 text-2xl font-bold text-white">Des solutions efficaces</h3>
            <p className="mt-4 text-gray-300 text-lg leading-relaxed">Une logistique optimisée pour une efficacité maximale</p>
          </div>
          <div className="bg-gradient-to-br from-white/20 to-white/5 backdrop-blur-lg p-8 rounded-xl border border-white/10 hover:border-white/30 transition-all duration-300 transform hover:-translate-y-2 group">
            <div className="bg-blue-500/20 p-3 rounded-lg w-fit group-hover:bg-blue-500/30 transition-colors">
              <Shield className="h-8 w-8 text-blue-400" />
            </div>
            <h3 className="mt-6 text-2xl font-bold text-white">Transport sécurisé</h3>
            <p className="mt-4 text-gray-300 text-lg leading-relaxed">La sécurité de votre cargaison est notre priorité</p>
          </div>
        </div>
      </div>
    </div>
    <About/>
    <Services/>
    <ImportExport/>
    <Contact/>
</>
  );
}