import { Handshake, Star, Lightbulb } from 'lucide-react';

export  default function About(){
  return (
    <div className="pt-32 pb-20 bg-white dark:bg-gray-900 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white sm:text-5xl">
            À Propos de Kingdor
          </h1>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Votre partenaire de confiance en logistique et commerce international
          </p>
        </div>

        {/* Mission & Vision */}
        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-lg hover:shadow-xl transition duration-300 cursor-pointer">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Notre Mission</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Faciliter le commerce international en fournissant des solutions logistiques innovantes 
              et fiables, tout en créant de la valeur pour nos clients et partenaires.
            </p>
            <a 
              href="/about-detail#mission"
              className="inline-flex items-center text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
            >
              Lire la suite
              <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-lg hover:shadow-xl transition duration-300 cursor-pointer">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Notre Vision</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-4">
              Devenir le leader de référence en Afrique dans le domaine de la logistique et du 
              commerce international, en innovant constamment nos services.
            </p>
            <a 
              href="/about-detail#vision"
              className="inline-flex items-center text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
            >
              Lire la suite
              <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>

        {/* Values */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-8">
            Nos Valeurs
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Handshake className="h-8 w-8 text-blue-600 dark:text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Intégrité</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous agissons avec honnêteté et transparence dans toutes nos interactions.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Star className="h-8 w-8 text-blue-600 dark:text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Excellence</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous visons l'excellence dans chaque aspect de nos services.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Lightbulb className="h-8 w-8 text-blue-600 dark:text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Innovation</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nous adoptons les nouvelles technologies pour améliorer nos services.
              </p>
            </div>
          </div>
        </div>

        {/* Team */}
      
        </div>
      </div>
    
  );
}