import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Service/Services';
import Blog from './components/Blog/Blog';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/About/About';
import ImportExport from './components/Import-export/ImportExport';
import Contact from './components/Contact/Contact';
import ServiceDetail from './components/Service/ServiceDetail';
import AboutDetail from './components/About/AboutDetail';
import Preloader from './components/Preloader';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Router className='bg-slate-100'>
          {/* Navbar */}
          <Navbar />

          {/* Routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/about-detail" element={<AboutDetail />} />
            <Route path="/import" element={<ImportExport />} />
            <Route path="/logistique" element={<Services />} />
            <Route path="/services/:slug" element={<ServiceDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<h1>404 - Page non trouvée</h1>} />
          </Routes>

          {/* Footer */}
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
