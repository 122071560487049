import React from 'react';
import { Clock10,MapPin, MailIcon, Menu, PhoneCall, X } from 'lucide-react';
import logo192 from '../assets/logo192.png';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div className="bg-white border-b-4 border-blue-600 dark:bg-gray-800 py-2 sticky top-0 text-gray-800 dark:text-gray-200 z-50 hidden lg:block transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <div className="bg-blue-600 p-2 rounded-full">
                <PhoneCall className="h-5 w-5 text-white" />
              </div>
              <span className="text-sm">+235 66303314</span>
            </div>

            <div className="flex items-center space-x-2">
              <div className="bg-blue-600 p-2 rounded-full">
                <MailIcon className="h-5 w-5 text-white" />
              </div>
              <span className="text-sm">contact@kingdor.td</span>
            </div>

            <div className="flex items-center space-x-2">
              <div className="bg-blue-600 p-2 rounded-full">
                <MapPin className="h-5 w-5 text-white" />
              </div>
              <span className="text-sm">Bololo, N'djamena</span>
            </div>

            <div className="flex items-center space-x-2">
              <div className="bg-blue-600 p-2 rounded-full">
                <Clock10 className="h-5 w-5 text-white" />
              </div>
              <span className="text-sm">9:00 AM - 5:00 PM</span>
            </div>
          </div>
        </div>
      </div>


      <nav className="bg-white dark:bg-gray-800 shadow-lg fixed w-full z-50 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20 items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center group">
                <img src={logo192} alt="Kingdor Logo" className="h-14 w-14 transition-transform duration-300 group-hover:scale-110" />
                <span className="ml-2 text-xl font-bold text-gray-800 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors">kingdor</span>
              </Link>
            </div>

            <div className="hidden lg:flex items-center space-x-8">
              <Link to="/" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">Accueil</Link>
              <Link to="/services" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">Services</Link>
              <Link to="/about" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">A propos</Link>
              <Link to="/Import" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">Import & Export</Link>
              <Link to="/Logistique" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">Logistique</Link>
              <Link to="/blog" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-blue-600 after:transition-all">Blog</Link>
              <Link to="/contact" className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 hover:shadow-lg">
                Contactez
              </Link>
            </div>

            <div className="lg:hidden flex items-center">
              <button 
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label={isOpen ? "Close menu" : "Open menu"}
              >
                {isOpen ? <X className="h-6 w-6 dark:text-white" /> : <Menu className="h-6 w-6 dark:text-white" />}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="lg:hidden absolute top-full left-0 right-0 bg-white dark:bg-gray-800 shadow-lg transition-all duration-300 ease-in-out">
            <div className="px-4 py-3 space-y-2">
              <Link to="/" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">Accueil</Link>
              <Link to="/services" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">Services</Link>
              <Link to="/about" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">A propos</Link>
              <Link to="/Import" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">Import & Export</Link>
              <Link to="/Logistique" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">Logistique</Link>
              <Link to="/blog" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-blue-50 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-300">Blog</Link>
              <Link to="/contact" className="block px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 text-center">
                Contactez
              </Link>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
